import L from 'leaflet';
import { tns } from 'tiny-slider/src/tiny-slider';

// CSS
import 'leaflet/dist/leaflet.css';

let map = null;
const mapWrapper = document.getElementById('map');
const homeSlider = document.getElementById('home-slider');

const leaflet = {
  url: 'https://api.mapbox.com/styles/v1/{id}/tiles/256/{z}/{x}/{y}?access_token={accessToken}',
  urlParams: {
    id: 'mapbox/light-v9',
    accessToken: 'pk.eyJ1IjoiZW1lbmRlbHNraSIsImEiOiJjazIzZjZhZ2gwamtoM2NtcXozOG1iZHF1In0.jaSfnYvGztiq0DHSDrTwiQ',
  },
};

function initLeaflet() {
  const { lat, lng, zoom } = mapWrapper.dataset;
  const center = {
    lat,
    lng,
  };

  // reset map
  if (map !== null) {
    map.off();
    map.remove();
  }

  // init map
  map = L.map(mapWrapper).setView(center, zoom);
  window.map = map;

  // Add proper layers
  L.tileLayer(leaflet.url, leaflet.urlParams).addTo(map);
}

function initSlider() {
  const slider = tns({ // eslint-disable-line no-unused-vars
    container: homeSlider,
    items: 1,
    slideBy: 1,
    autoplay: true,
    mouseDrag: true,
    gutter: 0,
    edgePadding: 0,
    autoplayButtonOutput: false,
    nav: false,
    controls: true,
    prevButton: '#home-slider-prev',
    nextButton: '#home-slider-next',
  });
}

function initHome() {
  if (mapWrapper) {
    initLeaflet();
  }

  if (homeSlider) {
    initSlider();
  }
}

export {
  initHome as default,
};
