function addSentinels(container, className, pos = false) {
  return Array.from(container.querySelectorAll('.js-sticky')).map((el) => {
    const sentinel = document.createElement('div');
    const { sticky } = el.dataset;

    if (pos) {
      const positioning = document.createElement('div');
      positioning.classList.add('sticky-positioning');
      el.before(positioning);

      let { top } = positioning.getBoundingClientRect();
      top += window.pageYOffset;

      document.documentElement.style.setProperty(`--offset-${sticky}`, `${top}px`);
    }

    sentinel.classList.add('sticky-sentinel', `sticky--${sticky}`, className);
    return el.parentElement.appendChild(sentinel);
  });
}

function fireEvent(stuck, target) {
  const e = new CustomEvent('sticky-change', { detail: { stuck, target } });
  document.dispatchEvent(e);
}

function observeHeaders(container) {
  const observer = new IntersectionObserver((records) => {
    records.forEach((record) => {
      const stickyTarget = record.target.parentElement.querySelector('.js-sticky');
      const { bottom: rBottom } = record.boundingClientRect;
      const { top: bTop, bottom: bBottom } = record.rootBounds;

      // Started sticking.
      // if (rBottom < bTop) {
      //   fireEvent(true, stickyTarget);
      // }

      // Stopped sticking.
      if (rBottom >= bTop && rBottom < bBottom) {
        fireEvent(false, stickyTarget);
      }
    });
  }, { threshold: [1] });

  // Add the top sentinels to each section and attach an observer.
  const sentinels = addSentinels(container, 'is-top', true);
  sentinels.forEach(el => observer.observe(el));
}

function observeFooter(container) {
  const observer = new IntersectionObserver((records) => {
    records.forEach((record) => {
      const stickyTarget = record.target.parentElement.querySelector('.js-sticky');
      const { bottom: rBottom } = record.boundingClientRect;
      const { top: bTop } = record.rootBounds;
      const ratio = record.intersectionRatio;

      // Started sticking.
      if (rBottom > bTop && ratio > 0.95) {
        fireEvent(true, stickyTarget);
      }

      // Stopped sticking.
      // if (rTop < bTop && rBottom < bBottom) {
      //   fireEvent(false, stickyTarget);
      // }
    });
  }, { threshold: [1] });

  // Add the bottom sentinels to each section and attach an observer.
  const sentinels = addSentinels(container, 'is-bottom', false);
  sentinels.forEach(el => observer.observe(el));
}

function navbarToggle() {
  const fnmap = {
    toggle: 'toggle',
    show: 'add',
    hide: 'remove',
  };

  const collapse = (selector, cmd) => {
    const targets = Array.from(document.querySelectorAll(selector));

    targets.forEach((target) => {
      target.classList[fnmap[cmd]]('show');
    });
  };

  const navbar = document.querySelector('.navbar');
  const triggers = document.querySelectorAll('.navbar-toggler');

  triggers.forEach(elm => elm.addEventListener('click', (e) => {
    e.preventDefault();

    const selector = elm.getAttribute('data-target');
    navbar.classList.toggle('is-open');
    collapse(selector, 'toggle');
  }, false));
}

function initHeader() {
  const navbar = document.querySelector('.navbar');

  if (navbar) {
    const stickyParent = document.querySelector('.wrap');

    observeHeaders(stickyParent);
    observeFooter(stickyParent);

    document.addEventListener('sticky-change', (e) => {
      const { target, stuck } = e.detail;
      target.classList.toggle('is-stuck', stuck);
    });

    // init toggling navbar
    navbarToggle();
  }
}

export {
  initHeader as default,
};
