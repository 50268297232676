import './scss/main.scss';
import 'tiny-slider/src/tiny-slider.scss';

// External

// Local
import initHome from './js/home';
import initHeader from './js/navbar';

// Execute
initHome();
initHeader();

// Make 100vh as 100vh
window.onresize = () => {
  document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight}px`);
};

window.onresize(); // called to initially set the height.
